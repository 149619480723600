const Config = {
    environment: process.env.NODE_ENV,
    publicUrl: process.env.PUBLIC_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    version: process.env.REACT_APP_VERSION,
    fileUrl: process.env.REACT_APP_FILE_URL,
    publicFileUrl: process.env.REACT_APP_PUBLIC_FILE_URL,
    noSessionCheck: Boolean(process.env.REACT_APP_NO_SESSION_CHECK || false),
    sentryDSN: process.env.REACT_APP_SENTRY_DSN,
    sentryEnvironment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
    googleGTM: process.env.REACT_APP_GOOGLE_GTM,
    oneTrustCookie: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_ONETRUST_ID
}

export default Config;
