import React, { createContext, useEffect, useState } from "react";
import useLocalstorage from "@rooks/use-localstorage";
import useFetch from 'use-http';
import { cleanup as messageBarCleanup } from "../components/MessageBar";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Config from "../Config";
import useInterval from "@rooks/use-interval";
import { sendUiEvent } from "../utils/uievents";
import * as UIEventTypes from "../utils/uieventtypes";

export const AuthContext = createContext({
    login: () => { },
    logout: () => { },
    isAuthenticated: false,
    userName: null
});

export function AuthProvider({ children, authorized, unauthorized, loadingView }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };
    // const [isAuthenticated, setAuthenticated] = useState(); // undefined means we need to check for authentication status
    const [profile, setProfile] = useState(undefined);
    const { get, post, response, error, cache } = useFetch(Config.apiUrl, { cachePolicy: 'no-cache', credentials: 'include', headers: { 'X-Application': 'AG' } });

    useInterval(() => {
        if (profile && !Config.noSessionCheck) {
            const checkStatus = async () => {
                try {
                    const response = await fetch(Config.apiUrl + '/caapi/v1/status', { cachePolicy: 'no-cache', credentials: 'include', headers: { 'X-Application': 'AG' } });
                    const result = await response.json();
                    if (result?.error === 'unauthorized' || result?.error === 'no_authorization_data') {
                        logout();
                    }
                }
                catch (error) {
                }
            };
            checkStatus();
        }
    }, 15 * 1000, true);

    const login = async (login, password) => {
        // setAuthenticated(true);
        const data = new FormData();
        data.append('login', login);
        data.append('password', password);
        const result = await post('/caapi/v1/login', data);
        if (response.ok) {
            sendUiEvent({ type: UIEventTypes.UIEVENT_UIACT, data: '/login' });
            await loadProfile(false);
            navigate(from, { replace: true });
            return result;
        }
        else {
            return result ? result : { error: error.name, error_description: error.message };
        }
    };

    const logout = async () => {
        // setAuthenticated(false);
        setProfile(null);
        messageBarCleanup();
        // sessionStorage.removeItem('sale_data');
        sendUiEvent({ type: UIEventTypes.UIEVENT_UIACT, data: '/logout' });
        await post('/caapi/v1/logout', new FormData());
        navigate('/');
        cache.clear();
        return true;
    };

    // useEffect(() => {
    //     async function LoadSession() {
    //         await get('/caapi/v1/status');
    //         if (response.ok) {
    //         }
    //         else {
    //             setProfile(null);
    //             console.log("Session expired");
    //         }
    //     }

    //     LoadSession();
    // }, []);

    const loadProfile = async (refreshOnly = false) => {
        const endpoint = refreshOnly ? '/caapi/v1/status' : '/caapi/v1/profile';
        const result = await get(endpoint);
        if (response.ok) {
            if (!refreshOnly) {
                // if (isAuthenticated !== true) {
                //     setAuthenticated(true);
                // }

                if (result?.session === 'end') {
                    setProfile(null);
                }
                else {
                    if (!profile) {
                        setProfile(result);
                    }
                    Sentry.setUser({ username: result.user.login, email: result.email });
                }
            }
        }
        else {
            // if (isAuthenticated !== false)
            //     setAuthenticated(false);
            // if (profile !== null)
            setProfile(null);
            // console.log("Session expired");
        }
    };

    useEffect(() => {
        loadProfile(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (profile === undefined) {
        return loadingView;
    }

    const values = {
        login,
        logout,

        isAuthenticated: profile ? true : false,
        userName: profile ? `${profile.firstname} ${profile.lastname}` : null
    };

    return (
        <AuthContext.Provider value={values}>
            {profile ? (authorized || children) : unauthorized}
        </AuthContext.Provider>
    );
}
