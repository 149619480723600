import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function RequireUnauth({ children, location }) {
    const auth = useContext(AuthContext);

    if (!auth.isAuthenticated) {
        return children;
    }

    return <Navigate to="/" replace />;
}

export default RequireUnauth;
