import Config from "../Config";

// const articleLoader = async ({ request, params }) => {
//     // const { get } = useFetch(Config.apiUrl, { credentials: 'include' });
//     // const result = await get(`/caapi/v1/articles/${params}`);
//     // return result;

//     return fetch(`${Config.apiUrl}/caapi/v1/articles/${params.articleId}`, { credentials: 'include' });
// }

export async function loader({ params }) {
    if (params.articleId === undefined) {
        // jeśli nie podano to pobierz HOME
        return fetch(`${Config.apiUrl}/caapi/v1/articles?symbol=HOME`, { credentials: 'include' });
    }
    return fetch(`${Config.apiUrl}/caapi/v1/articles/${params.articleId}`, { credentials: 'include' });
};
