import { useCallback, useEffect, useState, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import useFetch from 'use-http';
import * as Sentry from "@sentry/react";
import Config from "../Config";

export default function useAPI(entityName, entityId = null, parameters = {}, options = {}) {
    const auth = useContext(AuthContext);
    const [data, setData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { get, response, loading, error, cache } = useFetch(Config.apiUrl, { credentials: 'include', ...options });

    const loadData = useCallback(async () => {
        setErrorMessage(null);
        const id = entityId ? `/${entityId}` : '';
        let p = new URLSearchParams(parameters).toString();
        p = p.length > 0 ? `?${p}` : '';
        const result = await get(`/caapi/v1/${entityName}${id}${p}`);
        if (response.ok) {
            setData(result);
        }
        else {
            if (result?.error === 'unauthorized' || result?.error === 'no_authorization_data') {
                auth.logout();
            }
            else {
                Sentry.captureMessage('GET ' + (result?.error_description || error?.message));
                // setErrorMessage(result?.error_description || 'Wystąpił problem w trakcie pobierania danych.');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, response, entityName, entityId, parameters]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // powinno być ustawione [entityName, entityId, parameters], ale wtedy wpada w pętle odświeżania. Nie analizowałem dlaczego więc useAPI nadaje się tylko do pobierania jednorazowego

    return { data, loading, error, errorMessage, cache };
}
