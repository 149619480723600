import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { sendUiEvent } from '../utils/uievents';
import * as UIEventTypes from "../utils/uieventtypes";

function RequireAuth({ children, location, alternative = null }) {
    const auth = useContext(AuthContext);

    if (auth.isAuthenticated) {
        sendUiEvent({ type: UIEventTypes.UIEVENT_UIACT, data: location?.pathname });
        return children;
    }

    if (alternative) {
        return alternative;
    }
    else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
}

export default RequireAuth;
