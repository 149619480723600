
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import useFetch from 'use-http';
import useSessionstorage from "@rooks/use-sessionstorage";
import * as Sentry from "@sentry/react";
import './MessageBar.scss';
import Config from '../Config';

function MessageBar() {
    const [articles, setArticles] = useState();
    const [visibleArticles, setVisibleArticles] = useState([]);
    const [readMessages, setReadMessages] = useSessionstorage('readMessages', []);
    const { get, response, error } = useFetch(Config.apiUrl, { credentials: 'include' });

    const loadArticles = useCallback(async () => {
        setArticles(null);
        console.log(Config.messageBarArticles);
        const result = await get(`/caapi/v1/articles?type=${Config.messageBarArticles}`);
        if (response.ok) {
            setArticles(result);
        }
        else {
            Sentry.captureMessage('GETARTICLE ' + (result?.error_description || error?.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, response]);

    useEffect(() => {
        loadArticles();
    }, [loadArticles]);

    useEffect(() => {
        setVisibleArticles(articles?.filter(article => !readMessages?.includes(article.id)));
    }, [articles, readMessages]);

    const hideMessage = function (id) {
        const newReadMessages = [...readMessages || [], id];
        sessionStorage.setItem('readMessages', newReadMessages);
        setReadMessages(newReadMessages);
    };

    if (visibleArticles?.length > 0) {
        return (
            <Row className="full-width">
                <Col className="message-bar full-width">
                    {visibleArticles.map(article =>
                        <Alert key={article.id} variant="success" onClose={() => hideMessage(article.id)} dismissible>{article.content}</Alert>
                    )}
                </Col>
            </Row>
        );
    }
    return null;
}

export function cleanup() {
    sessionStorage.removeItem('readMessages');
}

export default MessageBar;
