import React from "react";
import ScrollToTop from "./controls/ScrollToTop";
import { AuthProvider } from "./context/AuthContext";
import PageContainer from "./components/PageContainer";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import { ArticleProvider } from "./context/ArticleContext";

function LoadingContainer() {
    return (
        <PageContainer className="page-loader"
            main={<Loading />}
        />
    );
}

export default function Layout() {
    return (
        <>
            <ScrollToTop />
            <React.Suspense fallback={<LoadingContainer />}>
                <AuthProvider
                    loadingView={
                        <LoadingContainer />
                    }
                    unauthorized={
                        <PageContainer
                            className='unauthorized'
                            header={<Header />}
                            main={<Outlet />}
                            footer={<Footer />}
                        />
                    }
                    authorized={
                        <ArticleProvider>
                            <PageContainer
                                className="authorized"
                                header={<Header />}
                                main={<Outlet />}
                                footer={<Footer />}
                            />
                        </ArticleProvider>
                    }
                />
            </React.Suspense>
        </>
    );
}
