import React from 'react';
import { loader as articleLoader } from "./article";
import Layout from '../Layout';
import RequireAuth from './RequireAuth';
import RequireUnauth from './RequireUnauth';

const Login = React.lazy(() => import('../pages/Public/Login'));
const Register = React.lazy(() => import('../pages/Public/Register'));
const ResetPassword = React.lazy(() => import('../pages/Public/ResetPassword'));
const ChangePassword = React.lazy(() => import('../pages/Public/ChangePassword'));
const PublicArticle = React.lazy(() => import('../pages/Public/PublicArticle'));
const PublicCookiePolicy = React.lazy(() => import('../pages/Public/CookiePolicy'));
const CookiePolicy = React.lazy(() => import('../pages/CookiePolicy'));
const PublicError404 = React.lazy(() => import('../pages/Public/Error404'));
const Home = React.lazy(() => import('../pages/Home'));
const Password = React.lazy(() => import('../pages/Profile/Password'));
const Agreements = React.lazy(() => import('../pages/Profile/Agreements'));
const Profile = React.lazy(() => import('../pages/Profile'));
// const Article = React.lazy(() => import('../pages/Article'));
const Error404 = React.lazy(() => import('../pages/Error404'));

const routes = [
    {
        path: "/", element: <Layout />, children: [
            { index: true, element: <RequireAuth><Home /></RequireAuth>, loader: articleLoader },
            { path: "/article/:articleId", element: <RequireAuth><Home /></RequireAuth>, loader: articleLoader },
            { path: "/tag/:tagName", element: <RequireAuth><Home /></RequireAuth>, loader: articleLoader },
            { path: "password", element: <RequireAuth><Password /></RequireAuth> },
            { path: "profile", element: <RequireAuth><Profile /></RequireAuth> },
            { path: "agreements", element: <RequireAuth><Agreements /></RequireAuth> },
            { path: "changepassword", element: <RequireAuth><ChangePassword /></RequireAuth> },
            { path: "cookie-policy-ag", element: <RequireAuth alternative={<PublicCookiePolicy />}><CookiePolicy /></RequireAuth> },

            { path: "public/:articleId", element: <RequireUnauth><PublicArticle /></RequireUnauth>, loader: articleLoader },
            { path: "login", element: <RequireUnauth><Login /></RequireUnauth> },
            { path: "register", element: <RequireUnauth><Register /></RequireUnauth> },
            { path: "resetpassword", element: <RequireUnauth><ResetPassword /></RequireUnauth> },
            { path: "*", element: <RequireAuth alternative={<PublicError404 />}><Error404 /></RequireAuth> }
        ]
    },
]

export default routes;
