import React, { createContext, useContext, useEffect, useState } from "react";
import useSessionstorage from "@rooks/use-sessionstorage";
import useFetch from 'use-http';
import { AuthContext } from '../context/AuthContext';

export const ArticleContext = createContext({
    currentArticle: null,
    setCurrentArticle: () => { },
});

export function ArticleProvider({ children }) {
    // const auth = useContext(AuthContext);
    const [currentArticle, setCurrentArticle] = useSessionstorage("article_id", null);

    return (
        <ArticleContext.Provider value={{
            currentArticle,
            setCurrentArticle,
        }}>
            {children}
        </ArticleContext.Provider>
    );
}
