
import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import './Loading.scss';

function Loading() {
    return (
        <Row className="loading">
            <Col xs>
                <Spinner animation="border" role="status">
                    {/* <span className="sr-only">Pobieram...</span> */}
                </Spinner>
            </Col>
        </Row>
    );
}

export default Loading;
