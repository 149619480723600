
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import "./PageContainer.scss";

function PageContainer({ header, main, footer, className }) {
    const [locationClass, setLocationClass] = useState('');
    const location = useLocation();

    useEffect(() => {
        let aclass = 'home';
        if (location && location.pathname !== '/') {
            const paths = location.pathname.split('/');
            aclass = paths?.[1].toLowerCase()
        }
        setLocationClass(aclass);
    }, [location]);

    const classname = classNames('page', className, locationClass);
    return (
        <div className={classname}>
            {header &&
                <header className="header">
                    {header}
                </header>
            }
            {main &&
                <main className="main">
                    {main}
                </main>
            }
            {footer &&
                <footer className="footer">
                    {footer}
                </footer>
            }
        </div>
    );
}

export default PageContainer;
