import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { ReactComponent as LogoBlack } from '../assets/galderma.svg';
import { AuthContext } from '../context/AuthContext';
import FooterNav from '../pages/Home/FooterNav';

function Footer() {
    const auth = useContext(AuthContext);

    return (
        <>
            <Container fluid className="footer-container">
                <Row className="content">
                    <Row className="footer-1"><LogoBlack /></Row>
                    <FooterNav />
                </Row>
            </Container>
            <Container fluid className="footer-container bottom-margin">
                <div className="bottom-margin-container"></div>
            </Container >
            <Container fluid className="footer-container">
                <Row className="content footer-3">
                    <Col className="content-menu center-xs" xs={12} lg={6}>
                        {auth.isAuthenticated && <>
                            <Link to="/profile"><span className="logged-in">Zalogowany jako</span> {auth.userName} </Link>
                            <Link to="/profile">Ustawienia profilu</Link>
                        </>}
                    </Col>
                    <Col className="content-menu center-xs" xs={12} lg={6}>
                        <Link to="/privacy">Polityka prywatności</Link>
                        <Link to="/cookie-policy-ag">Polityka cookies</Link>
                        <Link to="/privacy">Regulamin</Link>
                        <Link to="/contact">Kontakt</Link>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default Footer;
