import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import useAPI from '../../hooks/useAPI';
import { AuthContext } from '../../context/AuthContext';
import Config from '../../Config';
import { ArticleContext } from '../../context/ArticleContext';
import * as Sentry from "@sentry/react";
import { useFetch } from 'use-http';
import Loading from '../../components/Loading';
import './HeaderNav.scss';

function HeaderElements({ tagName = null, parentSymbol = null, symbol = null, parentArticleId = null, root = false }) {
    const auth = useContext(AuthContext);
    const articleContext = useContext(ArticleContext);
    const [articles, setArticles] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { get, response, loading, error } = useFetch(Config.apiUrl, { credentials: 'include' });

    const loadArticles = useCallback(async () => {
        setErrorMessage(null);
        // const query = { parentsymbol: "HOME" };
        // const query = { symbol: "HOME" };
        // const query = { parentarticle_id: null };
        const query1 = { parentsymbol: parentSymbol };
        const query2 = { parentarticle_id: parentArticleId };
        const query3 = { symbol: symbol };
        const query4 = { tagname: tagName };
        const p = new URLSearchParams(parentSymbol ? query1 : (symbol ? query3 : (tagName ? query4 : query2))).toString();
        const filter = p.length > 0 ? `?${p}` : '';
        const result = await get(`/caapi/v1/articles${filter}`);
        if (response.ok) {
            setArticles(result);
            // if (symbol) {
            //     articleContext.setCurrentArticle(result[0]);
            // }
        }
        else {
            if (result?.error === 'unauthorized' || result?.error === 'no_authorization_data') {
                auth.logout();
            }
            else {
                Sentry.captureMessage('GETARTICLES ' + (result?.error_description || error?.message));
                setErrorMessage(result?.error_description || 'Wystąpił problem w trakcie pobierania danych.');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, response]);

    useEffect(() => {
        loadArticles();
    }, [loadArticles]);


    if (articles?.length > 0) {
        return (
            <>
                {articles?.map(article => (
                    // <CategoryElement key={article.id} article={article} />
                    <Nav.Link key={article.id} as={Link} to={`/article/${article.id}`} eventKey={`/article/${article.id}`}><div>{article.name}</div></Nav.Link>
                ))}
            </>
        );
    }
    return <Loading />;
}

function HeaderNav() {
    const auth = useContext(AuthContext);
    const location = useLocation();

    if (!auth.isAuthenticated) {
        return null;
    }

    return (
        <Nav className="categories" activeKey={location?.pathname}>
            <HeaderElements parentSymbol="TOP" root={true} />
            {/* <HeaderElements tagName="Home" root={true} /> */}
            {/* <Nav.Link as={Link} to="/tag/home" eventKey="/tag/home"><div>Nowości</div></Nav.Link>
            <Nav.Link as={Link} to="/category/choroby" eventKey="/category/choroby"><div>Choroby</div></Nav.Link>
            <Nav.Link as={Link} to="/category/produkty" eventKey="/category/produkty"><div>Nasze produkty</div></Nav.Link>
            <Nav.Link as={Link} to="/tag/wyklady" eventKey="/tag/wyklady"><div>Wykłady</div></Nav.Link> */}
        </Nav>
    );
}

export default HeaderNav;
