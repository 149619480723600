import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import HeaderNav from '../pages/Home/HeaderNav';
import "./Header.scss";
import { ReactComponent as LogoWhite } from '../assets/akademia-logo.svg';

function Header() {
    const [state, setState] = useState(false);

    return (
        <Navbar collapseOnSelect expand="lg" sticky="top" onToggle={(expanded) => {
            setState(expanded);
        }}>
            <Container fluid>
                <Navbar.Brand as={Link} to="/"><LogoWhite /></Navbar.Brand>
                <Navbar.Toggle
                    aria-label="Menu"
                    aria-controls="basic-navbar-nav"
                    aria-expanded="true/false"
                    className={classNames('hamburger', 'hamburger--collapse', { 'is-active': state })}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <HeaderNav />
                    {/* <ProfileNav /> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
