import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loading from './components/Loading';
import PageContainer from './components/PageContainer';
import { dom } from '@fortawesome/fontawesome-svg-core';
// import pl from 'date-fns/locale/pl';
import * as Sentry from "@sentry/react";
import routes from './routing/Routes';
import Config from './Config';
import './App.scss';

dom.watch();

const router = createBrowserRouter(routes, {
    basename: Config.publicUrl
});

// if (import.meta.hot) {
//     import.meta.hot.dispose(() => router.dispose());
// }

function App() {
    return <RouterProvider
        router={router}
        fallbackElement={
            <PageContainer
                className="page-loader"
                main={<Loading />}
            />
        }
    />;
}

export default Sentry.withProfiler(App);
